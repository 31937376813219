<template>
  <div class="flex items-center flex-col pt-13 pb-7">
    <template v-if="!isFormSend">
      <h1 class="text-4xl mb-11"><b>Forgot</b> password</h1>

      <p class="mb-4 text-sm">
        Type your email & we’ll send you the recovery link
      </p>
      <Form class="auth-form" :validation-schema="schema" @submit="onSubmit">
        <ui-validation-input
          name="email"
          class="auth-form__field"
          label="Email"
        ></ui-validation-input>

        <ui-button
          class="mt-4 w-auth-form"
          :button-type="ButtonType.Blue"
          rounded
          >Send</ui-button
        >
      </Form>
    </template>

    <template v-else>
      <h1 class="text-4xl mb-20"><b>Check</b> your email</h1>
      <p class="text-sm text-center">
        We've sent password the recovery link <br />
        to {{ email }}
      </p>

      <ui-button
        v-if="!isResend"
        class="mt-20 w-auth-form"
        :button-type="ButtonType.Blue"
        @click="resend"
        rounded
        >Resend</ui-button
      >
      <ui-button
        v-else
        class="mt-20 w-auth-form"
        :button-type="ButtonType.Blue"
        @click="$router.go(-1)"
        rounded
        >Ok</ui-button
      >
    </template>
    <p class="mt-10 text-xs font-medium mb-2">
      Return to
      <router-link
        :to="{ name: ROUTER_NAMES.SIGN_IN }"
        class="text-red font-bold cursor-pointer"
        >sign-in</router-link
      >
    </p>

    <p class="text-center text-gray text-xs">
      By clicking “Sign in” I agree to the
      <router-link
        target="_blank"
        :to="{
          name: ROUTER_NAMES.PRIVACY,
          query: { from: ROUTER_NAMES.FORGOT_PASSWORD },
        }"
        >“Privacy Policy <br />
        & Terms and Conditions”</router-link
      >
    </p>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import UiValidationInput from "@/components/ui/UiValidationInput";
import UiButton from "@/components/ui/UiButton";
import { ref } from "vue";
import usersApi from "@/services/api/users-api";
import { ROUTER_NAMES } from "@/router/router.enum";
import { ButtonType } from "@/enums/button";

export default {
  components: {
    Form,
    UiValidationInput,
    UiButton,
  },
  setup() {
    const isFormSend = ref(false);
    const email = ref("");
    const isResend = ref(false);
    const schema = Yup.object().shape({
      email: Yup.string().email().required(),
    });

    const onSubmit = async (values) => {
      email.value = values.email;
      await usersApi.resetPassword(values.email);
      isFormSend.value = true;
    };

    const resend = async () => {
      await usersApi.resetPassword(email.value);
      isResend.value = true;
    };

    return {
      isResend,
      email,
      isFormSend,
      schema,
      onSubmit,
      resend,
      ROUTER_NAMES,
      ButtonType,
    };
  },
};
</script>

<style lang="scss" scoped>
.auth-form {
  @apply w-auth-form;
  @apply flex flex-col items-center;
  @apply mx-auto;
  @apply h-full;

  &__field {
    @apply w-full;
    @apply mb-5;
  }
}
</style>
